@import '../../scss/colors.scss';

.project, .admin-project-preview-div {
    margin: auto;
    padding-bottom: 2rem;
    max-width: 800px;
    h1{
      text-align: left;
      margin-bottom: 1.5rem;
      border-bottom: 1px solid $text-grey;
    }
    h2 {
      border-bottom: 1px solid $text-grey;
      margin: 1.5rem 0;
    }
    p {
      color: $text-grey;
      margin: 1rem 0;
    }
    ul {
      padding-left: 2rem;
      li {
        list-style-type: disc;
        color: $text-grey;
        margin: 0.5rem 0;
      }
    }
    a {
      color: $secondary;
      &:hover {
        text-decoration: underline;
      }
    }
    img[alt='thumbnail'] {
      width: 100%;
    }
    img[alt='thumbnail1'] {
      width: 100%;
    }
    pre {
      background-color: $bg-light;
      width: fit-content;
      padding: 1rem;
      border-radius: 0.25rem;
    }
}