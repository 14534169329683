@import "../../scss/colors.scss";

.projects {
  .container {
    .projects-content {
      width: 80%;
      margin: auto;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      margin-top: 15vh;
      margin-bottom: 110px;
      @media screen and (max-width: 767px) {
        margin-top: 5vh;
        width: 100%;
      }
      .projects-content-text {
        width: 300px;
        display: flex;
        flex-direction: column;
        padding-right: 2rem;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        .info {
          h1 {
            text-align: left;
          }
          p {
            margin-top: 1rem;
            color: $text-grey;
          }
          .btn-main {
            margin-top: 1rem;
            display: inline-block;
          }
        }
        .counter {
          display: inline-flex;
          align-items: center;
          margin-top: 2rem;
          @media screen and (max-width: 767px) {
            margin-top: 1rem;
            margin-bottom: 1rem;
            align-self: flex-end;
          }
          .counter-current,
          .counter-max {
            font-size: 1rem;
          }
          .counter-progress {
            display: flex;
            align-items: center;
            margin: 0 0.5rem;
            width: 140px;
            height: 2px;
            background-color: $text-grey;
            position: relative;
            overflow: visible;
            margin-top: 2px;
            .counter-progress-current {
              position: absolute;
              left: 0;
              height: 8px;
              z-index: 2;
              background-color: $secondary;
            }
          }
        }
      }
      .projects-content-img {
        width: calc(100% - 300px);
        position: relative;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        .laptop {
          width: 100%;
          position: relative;
          z-index: 4;
        }
        .window {
          position: absolute;
          top: 6.5%;
          left: 13%;
          width: 74%;
          height: 100%;
          z-index: 1;
          .thumbnail {
            position: relative;
            width: 100%;
            z-index: 3;
            backface-visibility: hidden;
          }
        }
      }
      .btn-prev,
      .btn-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 70px;
        height: 70px;
        fill: #fff;
        padding: 10px;
        &:hover {
          cursor: pointer;
        }
        @media screen and (max-width: 767px) {
          top: auto;
          bottom: -80px;
          transform: translateY(0%);
        }
      }
      .btn-prev {
        left: -90px;
        @media screen and (max-width: 767px) {
          left: 0;
        }
      }
      .btn-next {
        right: -90px;
        @media screen and (max-width: 767px) {
          right: 0;
        }
      }
    }
  }
}
