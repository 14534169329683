@import './fonts.scss';
@import './colors.scss';

.container {
  max-width: 1200px;
  margin: auto;
  padding: 0 1rem;
}

main .container {
  padding: 0 2rem;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

h1 {
  text-align: center;
  font-size: $title;
  letter-spacing: -0.25px;
}

h2 {
  font-size: $subtitle;
  letter-spacing: -0.25px;
}

body {
  position: relative;
  min-height: 100vh;
  padding-bottom: 3rem;
  font-family: $lato;
  line-height: 1.4;
  background-color: $bg-main;
  font-size: $body;
  color: $white;
}


footer {
  font-size: $caption;
  letter-spacing: 0.5px;
}

.btn-main {
  border: none;
  border-radius: 0.25rem;
  background-color: $main;
  color: $white;
  padding: 0.5rem 1rem;
  &:hover {
    cursor: pointer;
    background-color: $main-dark;
  }
}