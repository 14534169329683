@import '../../scss/colors.scss';

.admin {
  .admin-projects {
    ul {
      li {
        border-bottom: 1px solid $text-grey;
        margin-bottom: 0.5rem;
        // background-color: $text-grey;
      }
    }
  }
}