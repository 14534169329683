// Mobile
@media screen and (max-width: 767px) {
  *[class*='col'] {
    overflow: hidden;
    width: 100%;
  }
}

// Tablet
@media screen and (min-width: 768px) {
  .col-t-1 {
    width: 8.33%;
  }
  .col-t-2 {
    width: 16.66%;
  }
  .col-t-3 {
    width: 25%;
  }
  .col-t-4 {
    width: 33.33%;
  }
  .col-t-5 {
    width: 41.66%;
  }
  .col-t-6 {
    width: 50%;
  }
  .col-t-7 {
    width: 58.33%;
  }
  .col-t-8 {
    width: 66.66%;
  }
  .col-t-9 {
    width: 75%;
  }
  .col-t-10 {
    width: 83.33%;
  }
  .col-t-11 {
    width: 91.66%;
  }
  .col-t-12 {
    width: 100%;
  }
}

// Desktop
@media screen and (min-width: 1024px) {
  .col-d-1 {
    width: 8.33%;
  }
  .col-d-2 {
    width: 16.66%;
  }
  .col-d-3 {
    width: 25%;
  }
  .col-d-4 {
    width: 33.33%;
  }
  .col-d-5 {
    width: 41.66%;
  }
  .col-d-6 {
    width: 50%;
  }
  .col-d-7 {
    width: 58.33%;
  }
  .col-d-8 {
    width: 66.66%;
  }
  .col-d-9 {
    width: 75%;
  }
  .col-d-10 {
    width: 83.33%;
  }
  .col-d-11 {
    width: 91.66%;
  }
  .col-d-12 {
    width: 100%;
  }
}

// $mobile: 767px;
// $tablet: 768px;
// $desktop: 1023px;

// @mixin mobile {
//     @media (max-width: #{$mobile}) {
//         @content;
//     }
// }
// @mixin tablet {
//     @media (min-width: #{$tablet}) and (max-width: #{$desktop}) {
//         @content;
//     }
// }
// @mixin desktop {
//     @media (min-width: #{$desktop}) {
//         @content;
//     }
// }

