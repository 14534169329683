@import '../../scss/colors.scss';

.about {
  margin-top: 2rem;
  @media screen and (max-width: 767px) {
    margin-top: 1rem;
  }
  .container {
    .about-info, .about-at-work, .about-in-life, .about-contact {
      margin-bottom: 2rem;
      display: flex;
      flex-wrap: wrap;
      .c1 {
        width: 30%;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        .avatar {
          width: 100%;
          border-radius: 50%;
        }
        h2 {
          font-size: 1rem;
          letter-spacing: 0.25px;
          text-align: right;
          @media screen and (max-width: 767px) {
            text-align: left;
            margin-bottom: 1rem;
          }
        }
      }
      .c2 {
        width: 70%;
        padding-left: 2rem;
        font-size: 1rem;
        @media screen and (max-width: 767px) {
          padding-left: 0;
          width: 100%;
        }
        h1 {
          text-align: left;
          font-size: 3.052rem;
          margin-bottom: 2rem;
        }
        div {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 1rem;
          a {
            margin-right: 1rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            svg {
              width: 30px;
              height: 30px;
            }
          }
          p {
            background-color: $bg-light;
            color: $text-grey;
            padding: 0.2rem 0.5rem;
            &:hover {
              cursor: pointer;
            }
          }
          .msg {
            margin-left: 1rem;
            color: $main;
          }
        }
      }
    }

    .about-info p {
      font-size: 1.25rem;
    }
  }
}