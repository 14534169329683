@import '../../scss/colors.scss';

.sign-in {
  h1 {
    margin-top: 25vh;
    margin-bottom: 1rem;
  }
  .sign-in-form {
    max-width: 300px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    label, input {
      margin-bottom: 0.5rem;
    }
    input {
      padding: 0.5rem 1rem;
      width: 100%;
    }
    .err {
      color: crimson;
    }
    button {
      align-self: center;
      margin-top: 1rem;
    }
    .lost {
      margin-top: 1rem;
      a {
        color: $secondary;
      }
    }
  }
}