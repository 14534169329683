header {
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    .logo {
      display: inline-block;
      padding: 1rem 1rem;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    // nav {}
  }
}