body {
  margin: 0;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul {
  padding-left: 0;
  li {
    list-style: none;
  }
}

a {
  text-decoration: none;
  color: inherit;
}
