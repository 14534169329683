@import '../../scss/colors.scss';

@keyframes animate {
  from {opacity: 0}
  to { opacity: 1}
}

.welcome {
  .container {
    margin-top: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      animation-name: animate;
      animation-duration: 2s;
      animation-fill-mode: backwards;
    }
    p {
      margin-top: 1rem;
      text-align: center;
      color: $text-grey;
      animation-name: animate;
      animation-duration: 2s;
      animation-fill-mode: backwards;
      animation-delay: 0.8s;
    }
    a {
      margin-top: 2rem;
      animation-name: animate;
      animation-duration: 2s;
      animation-fill-mode: backwards;
      animation-delay: 1.5s;
    }
  }
}