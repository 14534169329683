nav {
  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;

    li {
      a, span {
        display: inline-block;
        padding: 1rem 1rem;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}