@import '../../scss/colors.scss';

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $bg-light;
  color: $text-grey;
  a {
    color: $white;
    &:hover {
      text-decoration: underline;
    }
  }
}