@import '../../scss/colors.scss';

@media screen and (min-width: 768px) {
  .admin-project-set, .admin-project-repos {
    margin-left: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .admin-project-set, .admin-project-repos {
    margin-top: 2rem;
  }
}

.admin {
  h1, h2 {
    margin-bottom: 1rem;
  }
  .flex {
    .admin-projects {
      background-color: $bg-light;
      padding: 1rem;
      height: 100%;
      
      // ul {
      //   li {}
      // }
    }
    
    .admin-project-set {
      background-color: $bg-light;
      padding: 1rem;

      .admin-project-set-form {
        display: flex;
        flex-direction: column;
        align-items: baseline;

        input {
          padding: 0.25rem 0.5rem;
        }
        textarea {
          width: 100%;
          padding: 0.25rem 0.5rem;
        }

        button {
          margin-top: 0.5rem;
        }
      }
    }
    
    .admin-project-repos {
      background-color: $bg-light;
      padding: 1rem;
      height: 100%;

      // h2 {
      // }
      ul {
        li {
          border-bottom: 1px solid $text-grey;
          margin-bottom: 0.5rem;
        }
      }
      button {
        margin-top: 0.5rem;
      }
    }
  }
  
  .admin-project-preview {
    margin-top: 2rem;
    border: 2px solid $bg-light;
    & > h2 {
      padding: 1rem;
      margin-bottom: 0;
      background-color: $bg-light;
    }
    // .admin-project-preview-div {
    //   // from './Project'
    // }
  }
}
